
    <div class="text-center">
        <div class="page-title">
            <h1>Football Craze Sweepstakes</h1>
        </div>
    </div>
    <div id="left-content" class="col-3">
        <h4>Photo Library</h4>
        <p>
            Look at your ticket stub and find the "Access Code", enter it in the box below and click "Enter":
        </p>
        <form id="photo-gallery" method="post">
            <div asp-validation-summary="ModelOnly" class="text-danger"></div>
            <div class="form-floating">
                <input asp-for="Input.SweepstakeCode" class="form-control" autocomplete="sweepstakecode" aria-required="true" />
                <label asp-for="Input.SweepstakeCode" class="form-label"></label>
                <span asp-validation-for="Input.SweepstakeCode" class="text-danger"></span>
            </div>
            <div style="margin-top:10px;">
                <button id="sweepstake-gallery-submit" (click)="goToSweepstakesGallery()" type="button" class="w-100 btn btn-lg btn-danger">Enter</button>
            </div>
        </form>
    </div>
    <div id="main-content" class="col-8">
        <p>
            You can now purchase Football Craze 2024 Sweepstakes tickets online:
            <a href="https://footballcrazesweepstakes.gemsup.co/product/index/purchase-tickets-2024">
                Click here to purchase tickets
            </a>  
        </p>
        <h2>Football Craze Sweepstakes {{sweepstakeYear | date: 'yyyy'}} Weekly Winners</h2>
        <ul>
            <li>
                <a href="../../assets/sweepstake-winners/SFWinnerListWeek01.txt" target="_blank">Week 1</a>
            </li>
        </ul>
        <h2>Football Craze Sweepstakes {{sweepstakeYear | date: 'yyyy'}}</h2>
    <p>
        Contact <a href="mailto:athletics@sfxcrossplains.org">athletics@sfxcrossplains.org</a> with any questions.
    </p>
    <h2>TOTAL PRIZES $18,000 - $1,000 GIVEN EACH WK FOR 18 WEEKS</h2>
    <table>
        <tr>
            <td>A.</td>
            <td class="team-names-padding">ARIZONA CARDINALS</td>
            <TD>I.</TD>
            <TD class="team-names-padding">DALLAS COWBOYS</TD>
            <TD>Q.</TD>
            <TD class="team-names-padding">MIAMI DOLPHINS</TD>
            <TD>Y.</TD>
            <TD>PITTSBURGH STEELERS</TD>
        </tr>
        <tr>
            <td>B.</td>
            <td class="team-names-padding">ATLANTA FOLCONS</td>
            <TD>J.</TD>
            <TD class="team-names-padding">DENVER BRONCOS</TD>
            <TD>R.</TD>
            <TD class="team-names-padding">MINNESOTA VIKINGS</TD>
            <TD>Z.</TD>
            <TD>LOS ANGELES CHARGERS</TD>
        </tr>
        <tr>
            <td>C.</td>
            <td class="team-names-padding">BALTIMORE RAVENS</td>
            <TD>K.</TD>
            <TD class="team-names-padding">DETROIT LIONS</TD>
            <TD>S.</TD>
            <TD class="team-names-padding">NEW ENGLAND PATRIOTS</TD>
            <TD>a.</TD>
            <TD>SAN FRANCISCO 49'RS</TD>
        </tr>
        <tr>
            <td>D.</td>
            <td class="team-names-padding">BUFFALO BILLS</td>
            <TD>L.</TD>
            <TD class="team-names-padding">GREEN BAY PACKERS</TD>
            <TD>T.</TD>
            <TD class="team-names-padding">NEW ORLEANS SAINTS</TD>
            <TD>b.</TD>
            <TD>SEATTLE SEAHAWKS</TD>
        </tr>
        <tr>
            <td>E.</td>
            <td class="team-names-padding">CAROLINA PANTHERS</td>
            <TD>M.</TD>
            <TD class="team-names-padding">HOUSTON TEXANS</TD>
            <TD>U.</TD>
            <TD class="team-names-padding">NEW YORK GIANTS</TD>
            <TD>C.</TD>
            <TD>LOS ANGELES RAMS</TD>
        </tr>
        <tr>
            <td>F.</td>
            <td class="team-names-padding">CHICAGO BEARS</td>
            <TD>N.</TD>
            <TD class="team-names-padding">INDIANAPOLIS COLTS</TD>
            <TD>V.</TD>
            <TD class="team-names-padding">NEW YORK JETS</TD>
            <TD>d.</TD>
            <TD>TAMPA BAY BUCANEERS</TD>
        </tr>
        <tr>
            <td>G.</td>
            <td class="team-names-padding">CINCINNATI BENGALS</td>
            <TD>O.</TD>
            <TD class="team-names-padding">JACKSONVILLE JAGUARS</TD>
            <TD>W.</TD>
            <TD class="team-names-padding">LAS VEGAS RAIDERS</TD>
            <TD>e.</TD>
            <TD>TENNESSEE TITANS</TD>
        </tr>
        <tr>
            <td>H.</td>
            <td class="team-names-padding">CLEVELAND BROWNS</td>
            <TD>P.</TD>
            <TD class="team-names-padding">KANSAS CITY CHIEFS</TD>
            <TD>X.</TD>
            <TD class="team-names-padding">PHILADELPHIA EAGLES</TD>
            <TD>f.</TD>
            <TD>WASHINGTON</TD>
        </tr>
    </table>
    <h2>DO NOT CALL THE SCHOOL OR PARISH.  CHECKS WILL BE MAILED AT THE END OF EACH MONTH</h2>
    <p>
        The St. Francis Xavier website is a collection of original digital photos by local artists.
        Purchase of sweepstakes ticket with a photo access code gains entry to the photo gallery where
        you may view, download or customize the photos through a third-party website. Access to the website
        is controlled by the 5-digit code on the face of the card. You may visit the site as often as you
        like. The photo website will be open until Febraury 8, 2025. Purchase of sweepstakes
        ticket with a photo access code enters you into the football sweepstakes.
    </p>
    <h2>Rules</h2>
    <ol>
        <li>
            ENTRY.  To enter, clearly print your name and address on the front of the sweepstakes
            ticket stub.  Return stub to an organization member.  All entries must be received by September 8,2024.
        </li>
        <li>
            NO PURCHASE NECESSARY. Open to legal residents of the United States, Washington D.C., and Puerto
            Rico.
            <p>
                To receive a no cost ticket, mail a stamped self-addressed envelope
                to: Saint Francis Football Sweepstakes 2947 Thinnes Street Cross Plains, WI 53528. Only One ticket will
                be sent, per self-addressed stamped envelope. Requests must be received before the start of the
                sweepstakes period.
            </p>
        </li>
        <li>
            EACH WEEK.  Your ticket contains 18 different 3-team combinations, each randomly generated, and
            randomly assigned to one of the 18 weeks of the pro football season.  Your weekly 3-team combination
            is unique.  Prizes are awarded after each week to the ticket whose teams score the most points and fewest
            combined points.
        </li>
        <li>
            SWEEPSTAKES PRIZES.  Each of the 18 weeks $1,000 in prizes will be awarded.  In the case of a tie, the prize
            is split only between the ties.  Minimum prize after split is under $5.00.  All prizes are awarded.
        </li>
        <li>
            PHOTO WEBSITE.  Purchase of the Photo Website sweepstakes ticket with photo access code, automatically enters
            you into the football sweepstakes.  It is not necessary to purchase an access card to receive a football sweepstakes
            ticket.
        </li>
    </ol>

    <p>
        For a list of winners, send a self-addressed stamped envelope to:<br />
        St. Francis Football<br />
        2947 Thinnes Street<br />
        Cross Plains, WI 53528
    </p>
    </div>
