<section fxLayout="column" fxFlexAlign="stretch">
  <div id="main-content">
    <div class="row">
      <div class="col-9">
        <h2>2024 SFX Youth Football Season</h2>
        <p>
          <b>Registration Open Now</b> for the 2024 Youth Football Season starting August 6th with equipment handout on
          August 4th.
        </p>
        <p>
          We have 4th and 5th grade Rookie (8 Man) Contact Football playing in the Forward Youth Football League
        </p>
        <p>
          We have 6th grade 11-man Playing in the Badger Central Alliance League
        </p>
        <p>
          Our 7th and 8th grades play combined with Middleton Youth Football. They play in the Jr. High Alliance League.
        </p>
        <ul>
          <li>Aug. 4 - Players equipment handout - SFX Field. 6th grade at 4:30 / 5th grade at 5:00 PM / 4th grade at
            5:30 pm</li>
          <li>Aug. 6 - 1st practice - SFX Field. All grades Teams 5:00 - 6:30 pm</li>
          <li>Aug. 7th , 8th, 12th, 13th, 14th - practice from 5:00 - 6:30 pm</li>
          <li>We will finish August with a team Photo and cookout get together.</li>
          <li>August 24th Scrimmage for League Teams - SFX Field - 9:00 am</li>
          <li>Once school starts (9/3) - practice 3 days per week - 5:00 - 6:30 PM and games on Sat. mornings </li>
          <li>The Fee is $160.00</li>
          <li>Register at: <a
              href="https://stfrancisathleticassn.gemsup.co/product/index/st-francis-xavier-youth-football--2024-season-grades-4---6">https://stfrancisathleticassn.gemsup.co</a>
          </li>
          <li>Location is SFX Football Field 2947 Thinnes St, Cross Plains, WI</li>
        </ul>
      </div>
      <div class="col-3">
        <figure class="figure">
          <img src="../../assets/images/home/green-bay-flag-football-catch.jpg" class="figure-img img-fluid rounded"
            alt="Playing Flag Football in Green Bay">
          <figcaption class="figure-caption">Playing Flag Football in Green Bay</figcaption>
        </figure>
        <figure class="figure">
          <img src="../../assets/images/home/green-bay-flag-football-team.jpg" class="figure-img img-fluid rounded"
            alt="Playing Flag Football in Green Bay">
          <figcaption class="figure-caption">Playing Flag Football in Green Bay</figcaption>
        </figure>
        <figure class="figure">
          <img src="../../assets/images/home/cecil-martin-home-600.jpg" class="figure-img img-fluid rounded"
            alt="Cecil Martin &amp; Ryan Groy Camp">
          <figcaption class="figure-caption">Cecil Martin &amp; Ryan Groy Camp</figcaption>
        </figure>
        <figure class="figure">
          <img src="../../assets/images/home/SFX-Game-home-600.jpg" class="figure-img img-fluid rounded"
            alt="SFX Football Game">
          <figcaption class="figure-caption">SFX Football Game</figcaption>
        </figure>
        <figure class="figure">
          <img src="../../assets/images/home/jim-leonhard-home-600.jpg" class="figure-img img-fluid rounded"
            alt="Jim Leonhard Camp">
          <figcaption class="figure-caption">Jim Leonhard Camp</figcaption>
        </figure>
      </div>
    </div>
    <div class="sponsors">
      <img src="../../assets/images/home/Desmet-Logo.png" />
      <img src="../../assets/images/home/latitude.png" />
      <img src="../../assets/images/home/oldwi.png" />
      <img src="../../assets/images/home/sportsadvantEDGElogo.jpg" />
      <img src="../../assets/images/home/uwp.png" />
      <img src="../../assets/images/home/usa-football.png" />
  </div>
  </div>
</section>